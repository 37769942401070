import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  firstname: string;
  lastname: string;
  role: string;
  last_active_at: string;
  email: string;
  password: string;
  banned: string;
  wing_id: number;
  api_token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class FlightPlanModule
  extends VuexModule
  implements UserAuthInfo
{
  errors = {};
  responseStatus = true;
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get authentification errors
   * @returns array
   */
  get getFlightErrors() {
    return this.responseStatus;
  }

  @Mutation
  [Mutations.SET_RESPONSE_MESSAGE]() {
    this.responseStatus = false;
  }
  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }
  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.NEWFLIGHT](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.post("flights/create", credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
      });
  }
  @Action
  [Actions.PILOTS_MINIFIED_LIST](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.get("minified/crew/pilots/" + credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.CAPTAINS_MINIFIED_LIST](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.get("minified/crew/captains/" + credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.LOAD_MASTERS_MINIFIED_LIST](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.get("minified/crew/loadmasters/" + credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.MECHANICAl_MINIFIED_LIST](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.get("minified/crew/engineers/" + credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.CREW_MAN_MINIFIED_LIST](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.get("minified/crew/crewmen/" + credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.AVIONICS_MINIFIED_LIST](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.get("minified/crew/avionics/" + credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  //Remote Pilots
  @Action
  [Actions.REMOTE_PILOTS_MINIFIED_LIST](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.get("minified/crew/remote/" + credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  //OnBoard Pilot
  @Action
  [Actions.ONBOARD_PILOTS_MINIFIED_LIST](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.get("minified/crew/onboard/" + credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  //WSO
  @Action
  [Actions.WSO_MINIFIED_LIST](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.get("minified/crew/wso/" + credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  //Payload Operator
  @Action
  [Actions.PAYLOAD_OPERATOR_MINIFIED_LIST](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.get("minified/crew/payload/" + credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  // CALENDAR_FLIGHT_STATUS_COLORS_LIST
  @Action
  [Actions.CALENDAR_FLIGHT_STATUS_COLORS_LIST]() {
    ApiService.setHeader();
    return ApiService.get("flights/status/colors")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.MISSION_TYPES_MINIFIED_LIST](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.get("minified/missions/" + credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.AIRCRAFT_MAKE_MINIFIED_LIST](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.get("minified/make/" + credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.BASES_MINIFIED_LIST]() {
    ApiService.setHeader();
    return ApiService.get("minified/bases")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.TASKS_MINIFIED_LIST]() {
    ApiService.setHeader();
    return ApiService.get("minified/tasks")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.CARGO_LIST]() {
    ApiService.setHeader();
    return ApiService.get("flights/cargo")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.BENEFICIARIES_LIST]() {
    ApiService.setHeader();
    return ApiService.get("flights/beneficiaries")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.AREAS_LIST]() {
    ApiService.setHeader();
    return ApiService.get("minified/areas")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.CALENDAR_AREAS_LIST]() {
    ApiService.setHeader();
    return ApiService.get("calendar/areas")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.CALENDAR_FLIGHTS_LIST]() {
    ApiService.setHeader();
    return ApiService.get("calendar/flights")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.CALENDAR_PLANNER_FLGHTS_LIST]() {
    ApiService.setHeader();
    return ApiService.get("calendar/planner/flights")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.SQUADRONS_LIST]() {
    ApiService.setHeader();
    return ApiService.get("calendar/squadrons")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.NOTIFICATIONS_LIST]() {
    ApiService.setHeader();
    return ApiService.get("calendar/operator/flights/conflicts")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.CALENDAR_CONFIRM_FLIGHT](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.post("calendar/operator/flights/confirm", credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
        return response.data;
      });
  }
  @Action
  [Actions.CALENDAR_REJECT_FLIGHT](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.post("calendar/operator/flights/reject", credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
        return response.data;
      });
  }
  @Action
  [Actions.CALENDAR_COMMENT_FLIGHT](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.post("calendar/operator/flights/comment", credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
        return response.data;
      });
  }
  @Action
  [Actions.PLANNER_FLIGHTS_LIST]() {
    ApiService.setHeader();
    return ApiService.get("planner/flights/listing")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
        return response.data;
      });
  }
  @Action
  [Actions.PLANNER_GET_FLIGHT](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.get("planner/flight/" + credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
        return response.data;
      });
  }
  @Action
  [Actions.PLANNER_FLIGHTS_COUNT_LIST]() {
    ApiService.setHeader();
    return ApiService.get("planner/flights/status")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
        return response.data;
      });
  }
  @Action
  [Actions.PLANNER_FLIGHT_ADD_NEW_AREA](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.post("planner/flight/new/area", credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
        return response.data;
      });
  }
  @Action
  [Actions.PLANNER_FLIGHT_DELETE_AREA](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.delete("planner/flight/delete/area/" + credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
        return response.data;
      });
  }
  @Action
  [Actions.PLANNER_FLIGHT_EDIT_AREA](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.post("planner/flight/edit/area", credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
        return response.data;
      });
  }
  @Action
  [Actions.PLANNER_FLIGHT_UPDATE_FLIGHT_DETAILS](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.post("planner/flight/update/details", credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
        return response.data;
      });
  }
  @Action
  [Actions.PLANNER_GET_SQUADRON_FLIGHT](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.get("planner/flight/selected/squadron/" + credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
        return response.data;
      });
  }
  @Action
  [Actions.PLANNER_FLIGHT_ADD_NEW_AIRCRAFT](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.post("planner/flight/new/aircraft", credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response", response);
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
        return response.data;
      });
  }
  @Action
  [Actions.PLANNER_FLIGHT_UPDATE_AIRCRAFT_DETAILS](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.post("planner/flight/update/aircraft", credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response", response);
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
        return response.data;
      });
  }
  @Action
  [Actions.PLANNER_FLIGHT_DELETE_AIRCRAFT](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.delete("planner/flight/delete/aircraft/" + credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
        return response.data;
      });
  }
}
