import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router/clean";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import "@/core/plugins/prismjs";
import "bootstrap";
const app = createApp(App);

import PrimeVue from "primevue/config";

import Avatar from "primevue/avatar";

import BadgeDirective from "primevue/badgedirective";

import Button from "primevue/button";

import Card from "primevue/card";

import Checkbox from "primevue/checkbox";

import ColorPicker from "primevue/colorpicker";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmPopup from "primevue/confirmpopup";
import ConfirmationService from "primevue/confirmationservice";

import DataTable from "primevue/datatable";

import Dialog from "primevue/dialog";

import Dropdown from "primevue/dropdown";

import Image from "primevue/image";

import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import InputNumber from "primevue/inputnumber";
import Message from "primevue/message";
import MultiSelect from "primevue/multiselect";
import Textarea from "primevue/textarea";
import Paginator from "primevue/paginator";

import ProgressSpinner from "primevue/progressspinner";
import Rating from "primevue/rating";
import RadioButton from "primevue/radiobutton";
import Ripple from "primevue/ripple";
import Row from "primevue/row";
import SelectButton from "primevue/selectbutton";

import Skeleton from "primevue/skeleton";

import SpeedDial from "primevue/speeddial";
import SplitButton from "primevue/splitbutton";
import StyleClass from "primevue/styleclass";

import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
app.use(PrimeVue, { ripple: true });
app.use(ConfirmationService);
app.use(ToastService);

import Inplace from "primevue/inplace";
import Calendar from "primevue/calendar";
app.component("PrimeCalendar", Calendar);
app.component("PrimeInplace", Inplace);
app.directive("badge", BadgeDirective);
app.directive("ripple", Ripple);
app.directive("styleclass", StyleClass);
app.component("PrimeTextarea", Textarea);
app.component("PrimeSplitButton", SplitButton);
app.component("PrimeColumn", Column);
app.component("PrimeAvatar", Avatar);
app.component("PrimeButton", Button);
app.component("ColumnGroup", ColumnGroup);
app.component("DataTable", DataTable);
app.component("ConfirmDialog", ConfirmDialog);
app.component("ConfirmPopup", ConfirmPopup);
app.component("PrimeDialog", Dialog);
app.component("PrimeDropdown", Dropdown);
app.component("PrimeCard", Card);
app.component("PrimeCheckbox", Checkbox);
app.component("ColorPicker", ColorPicker);
app.component("PrimeImage", Image);
app.component("PrimeInputMask", InputMask);
app.component("PrimeInputNumber", InputNumber);
app.component("InputSwitch", InputSwitch);
app.component("PrimeInputText", InputText);
app.component("PrimeMessage", Message);
app.component("PrimeMultiSelect", MultiSelect);
app.component("PrimePaginator", Paginator);
app.component("PrimeProgressSpinner", ProgressSpinner);
app.component("RadioButton", RadioButton);
app.component("PrimeRating", Rating);
app.component("PrimeRow", Row);
app.component("SelectButton", SelectButton);
app.component("PrimeSkeleton", Skeleton);
app.component("SpeedDial", SpeedDial);
app.component("PrimeToast", Toast);
import Tooltip from "primevue/tooltip";

app.directive("tooltip", Tooltip);
app.use(store);
app.use(router);
app.use(ElementPlus);
ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
// apply global filters
app.config.globalProperties.$filters = {
  currency(value) {
    return "$" + new Intl.NumberFormat("en-US").format(value);
  },
};
