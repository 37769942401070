enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  SET_THEME_MODE_ACTION = "setThemeModeAction",
  // actions new flight
  NEWFLIGHT = "newFlight",
  PILOTS_MINIFIED_LIST = "PilotMinifiedList",
  REMOTE_PILOTS_MINIFIED_LIST = "RemotePilotMinifiedList",
  ONBOARD_PILOTS_MINIFIED_LIST = "OnBoardPilotMinifiedList",
  WSO_MINIFIED_LIST = "WSOMinifiedList",
  PAYLOAD_OPERATOR_MINIFIED_LIST = "PayloadOperatorMinifiedList",
  CAPTAINS_MINIFIED_LIST = "CaptainMinifiedList",
  LOAD_MASTERS_MINIFIED_LIST = "LoadMasterMinifiedList",
  MECHANICAl_MINIFIED_LIST = "MechanicalMinifiedList",
  CREW_MAN_MINIFIED_LIST = "CrewManMinifiedList",
  AVIONICS_MINIFIED_LIST = "AvionicsMinifiedList",
  AIRCRAFT_MAKE_MINIFIED_LIST = "AircraftMakeMinifiedList",
  MISSION_TYPES_MINIFIED_LIST = "MissionTypesMinifiedList",
  TASKS_MINIFIED_LIST = "TasksMinifiedList",
  CARGO_LIST = "CargoList",
  BENEFICIARIES_LIST = "BeneficiariesList",
  AREAS_LIST = "AreasList",
  CALENDAR_AREAS_LIST = "CalendarAreasList",
  CALENDAR_FLIGHTS_LIST = "CalendarFlightsList",
  SQUADRONS_LIST = "SquadronList",
  CALENDAR_PLANNER_FLGHTS_LIST = "CalendarPlannerFlightList",
  NOTIFICATIONS_LIST = "NotificationList",
  CALENDAR_FLIGHT_STATUS_COLORS_LIST = "CalendarFlightStatusColorsList",
  CALENDAR_CONFIRM_FLIGHT = "CalendarConfirmFlight",
  CALENDAR_REJECT_FLIGHT = "CalendarRejectFlight",
  CALENDAR_COMMENT_FLIGHT = "CalendarCommentFlight",
  PLANNER_FLIGHTS_LIST = "PlannerFlightsList",
  PLANNER_FLIGHTS_COUNT_LIST = "PlannerFlightsCountList",
  PLANNER_GET_FLIGHT = "PlannerGetFlight",
  PLANNER_FLIGHT_ADD_NEW_AREA = "PlannerFlightAddNewArea",
  PLANNER_FLIGHT_DELETE_AREA = "PlannerFlightDeleteArea",
  PLANNER_FLIGHT_EDIT_AREA = "PlannerFlightEditArea",
  PLANNER_FLIGHT_UPDATE_FLIGHT_DETAILS = "PlannerFlightUpdateFlightDetails",
  PLANNER_GET_SQUADRON_FLIGHT = "PlannerGetSquadronFlight",
  PLANNER_FLIGHT_ADD_NEW_AIRCRAFT = "PlannerFlightAddNewAircraft",
  BASES_MINIFIED_LIST = "BasesMinifiedList",
  PLANNER_FLIGHT_UPDATE_AIRCRAFT_DETAILS = "PlannerFlightUpdateAircraftDetails",
  PLANNER_FLIGHT_DELETE_AIRCRAFT = "PlannerFlightDeleteAircraft",
  ADMIN_ADD_NEW_MEMBER = "AdminAddNewMember",
  ADMIN_MEMBERS_LISTING = "AdminMembersListing",
  ADMIN_DELETE_MEMBER = "AdminMemberDelete",
  ADMIN_MEMBERS_ROLE_COUNTING = "AdminMemberRoleCounting",
  ADMIN_UPDATE_MEMBER_DETAILS = "AdminUpdateMemberDetails",
  ADMIN_TEAM_LISTING = "AdminTeamListing",
  CREW_ROLES = "CrewRoles",
  WINGS_LIST = "WingsRoles",
  ADMIN_ADD_NEW_TEAM_Member = "AdminAddNewTeamMember",
  CREW_STUDIES_LEVELS = "CrewStudyLevels",
  CREW_STUDIES_SPECIFICALLY = "CrewStudiesSpecifically",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_RESPONSE_MESSAGE = "setResponseMessage",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_THEME_MODE_MUTATION = "setThemeModeMutation",
}

export { Actions, Mutations };
