import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  firstname: string;
  lastname: string;
  role: string;
  last_active_at: string;
  email: string;
  password: string;
  banned: string;
  wing_id: number;
  api_token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AdminModule extends VuexModule implements UserAuthInfo {
  errors = {};
  responseStatus = true;
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get authentification errors
   * @returns array
   */
  get getAdminErrors() {
    return this.responseStatus;
  }

  @Mutation
  [Mutations.SET_RESPONSE_MESSAGE]() {
    this.responseStatus = false;
  }
  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }
  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }
  @Action
  [Actions.CREW_ROLES](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.get("configuration/crew/roles")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.CREW_STUDIES_LEVELS]() {
    ApiService.setHeader();
    return ApiService.get("configuration/study/levels")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.CREW_STUDIES_SPECIFICALLY]() {
    ApiService.setHeader();
    return ApiService.get("configuration/study/specifically")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        //    this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.ADMIN_ADD_NEW_MEMBER](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.post("admin/crew/create", credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
      });
  }
  @Action
  [Actions.ADMIN_UPDATE_MEMBER_DETAILS](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.post("admin/crew/member/update/details", credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
      });
  }

  @Action
  [Actions.ADMIN_MEMBERS_LISTING]() {
    ApiService.setHeader();
    return ApiService.get("admin/crew/listing")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
      });
  }
  @Action
  [Actions.ADMIN_TEAM_LISTING]() {
    ApiService.setHeader();
    return ApiService.get("admin/team/listing")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
      });
  }
  @Action
  [Actions.WINGS_LIST]() {
    ApiService.setHeader();
    return ApiService.get("configuration/wings")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
      });
  }
  @Action
  [Actions.ADMIN_DELETE_MEMBER](credentials) {
    console.log("credentials", credentials);
    ApiService.setHeader();
    return ApiService.delete("admin/crew/member/delete/" + credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
        return response.data;
      });
  }
  @Action
  [Actions.ADMIN_MEMBERS_ROLE_COUNTING]() {
    ApiService.setHeader();
    return ApiService.get("admin/crew/member/counting")
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
      });
  }
  @Action
  [Actions.ADMIN_ADD_NEW_TEAM_Member](credentials) {
    ApiService.setHeader();
    return ApiService.post("admin/team/member/create", credentials)
      .then(({ data }) => {
        return data;
      })
      .catch(({ response }) => {
        console.log("response.data", response.data);
        this.context.commit(
          Mutations.SET_RESPONSE_MESSAGE,
          response.data.success
        );
      });
  }
}
