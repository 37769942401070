import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: "/",
  //   redirect: "/",
  //   component: () => import("@/layout/Layout.vue"),
  //   children: [
  //     {
  //       path: "/dashboard",
  //       name: "dashboard",
  //       component: () => import("@/views/Dashboard.vue"),
  //     },
  //   ],
  // },
  {
    path: "/",
    redirect: "/planner",
    component: () => import("@/layout/PlannerLayout.vue"),
    children: [
      {
        path: "/planner",
        name: "dashboardPlanner",
        component: () => import("@/views/DashboardPlanner.vue"),
        meta: {
          name: "dashboardPlanner",
          requiresAuth: true,
          PlannerAuth: true,
          OperatorAuth: false,
          AdminAuth: false,
        },
      },
      // {
      //   path: "/planner/new-flight-plan2",
      //   name: "NewFlightPlan",
      //   meta: {
      //     name: "NewFlightPlan",
      //     requiresAuth: true,
      //     PlannerAuth: true,
      //     OperatorAuth: false,
      //   },
      //   component: () => import("@/views/Planner/NewFlightPlan.vue"),
      // },
      {
        path: "/planner/new-flight-plan",
        name: "NewFlightPlanWizard",
        meta: {
          name: "NewFlightPlanWizard",
          requiresAuth: true,
          PlannerAuth: true,
          OperatorAuth: false,
        },
        component: () => import("@/views/Planner/NewFlightPlanWizard.vue"),
      },
      {
        path: "/planner/flight-requests",
        name: "FlightRequests",
        meta: {
          name: "FlightRequests",
          requiresAuth: true,
          PlannerAuth: true,
          OperatorAuth: false,
        },
        component: () => import("@/views/Planner/FlightRequests.vue"),
      },
      {
        path: "/planner/flight-details/:id",
        name: "FlightDetails",
        meta: {
          name: "FlightDetails",
          requiresAuth: true,
          PlannerAuth: true,
          OperatorAuth: false,
          AdminAuth: false,
        },
        component: () =>
          import("@/PlannerComponents/Flights/Edit/FlightDetails.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/operator",
    component: () => import("@/layout/OperatorLayout.vue"),
    children: [
      {
        path: "/operator",
        name: "dashboardOperator",
        meta: {
          name: "dashboardOperator",
          requiresAuth: true,
          PlannerAuth: false,
          OperatorAuth: true,
          AdminAuth: false,
        },
        component: () => import("@/views/DashboardOperator.vue"),
      },
      {
        path: "/operator/flight-requests",
        name: "OperatorFlightRequests",
        meta: {
          name: "OperatorFlightRequests",
          requiresAuth: true,
          PlannerAuth: false,
          OperatorAuth: true,
          AdminAuth: false,
        },
        component: () => import("@/views/Operator/FlightRequests.vue"),
      },
      {
        path: "/operator/aircraft-area-allocation",
        name: "AirCraftAreasAllocation",
        meta: {
          name: "AirCraftAreasAllocation",
          requiresAuth: true,
          PlannerAuth: false,
          OperatorAuth: true,
          AdminAuth: false,
        },
        component: () => import("@/views/Operator/AirCraftAreaAllocation.vue"),
      },
      {
        path: "/operator/area-management",
        name: "AreasBlockManagement",
        meta: {
          name: "AreasBlockManagement",
          requiresAuth: true,
          PlannerAuth: false,
          OperatorAuth: true,
          AdminAuth: false,
        },
        component: () => import("@/views/Operator/AreaBlockManagement.vue"),
      },
    ],
  },
  {
    path: "/",
    redirect: "/admin",
    component: () => import("@/layoutAdmin/AdminLayout.vue"),
    children: [
      {
        path: "/admin",
        name: "dashboardAdmin",
        meta: {
          name: "dashboardAdmin",
          requiresAuth: true,
          PlannerAuth: false,
          OperatorAuth: false,
          AdminAuth: true,
        },
        component: () => import("@/views/DashboardAdmin.vue"),
      },
      {
        path: "/admin/crew/management",
        name: "CrewManagement",
        meta: {
          name: "CrewManagement",
          requiresAuth: true,
          PlannerAuth: false,
          OperatorAuth: false,
          AdminAuth: true,
        },
        component: () => import("@/views/Admin/CrewManagement.vue"),
      },
      {
        path: "/admin/team/management",
        name: "GroundTeamManagement",
        meta: {
          name: "GroundTeamManagement",
          requiresAuth: true,
          PlannerAuth: false,
          OperatorAuth: false,
          AdminAuth: true,
        },
        component: () =>
          import("@/views/Admin/GroundTeam/GroundTeamManagement.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/AuthFullPage.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        meta: {
          name: "sign-in",
          requiresAuth: false,
          PlannerAuth: false,
          OperatorAuth: false,
          AdminAuth: false,
        },
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      // {
      //   path: "/sign-up",
      //   name: "sign-up",
      //   meta: {
      //     name: "sign-up",
      //     requiresAuth: false,
      //     PlannerAuth: false,
      //     OperatorAuth: false,
      //   },
      //   component: () =>
      //     import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      // },
      {
        path: "/password-reset",
        name: "password-reset",
        meta: {
          name: "password-reset",
          requiresAuth: false,
          PlannerAuth: false,
          OperatorAuth: false,
          AdminAuth: false,
        },
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    meta: {
      name: "404",
      requiresAuth: false,
      PlannerAuth: false,
      OperatorAuth: false,
      AdminAuth: false,
    },
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // const authUser = store.getters.currentUser;
  const authUser = window.localStorage.getItem("id_session");
  const token = window.localStorage.getItem("id_token");
  //console.log("requiresAuth", store.getters.currentUser);
  if (to.meta.requiresAuth) {
    store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });
    if (!token) {
      next({ name: "sign-in" });
    } else if (to.meta.PlannerAuth) {
      //   const VuexUser = store.state.user
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const User = JSON.parse(authUser!);
      if (User.role === "Planner") {
        next();
      } else {
        router.go(-1);
      }
    } else if (to.meta.OperatorAuth) {
      //  const VuexUser = store.state.user
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const User = JSON.parse(authUser!);
      if (User.role === "Operator") {
        next();
      } else {
        router.go(-1);
      }
    } else if (to.meta.AdminAuth) {
      //  const VuexUser = store.state.user
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const User = JSON.parse(authUser!);
      if (User.role === "Admin") {
        next();
      } else {
        router.go(-1);
      }
    }
  } else {
    next();
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
